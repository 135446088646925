<template>
  <AppTopNav></AppTopNav>
  <AppLayoutCommon>
    <XtxBread>
      <XtxBreadItem :path="'/'">首页</XtxBreadItem>
      <XtxBreadItem>交易专区</XtxBreadItem>
    </XtxBread>
    <h2>交易专区</h2>
    <div class="main-content">
      <div class="search">
        <div class="search-input">
          <label for="">商品名称</label>
          <Select
            v-model="params.productName"
            placeholder="请选择商品名称"
            clearable
            @on-clear="getDataList"
            @on-change="getDataList"
          >
            <Option
              v-for="item of productNameOptions"
              :key="item"
              :value="item"
            >
              {{ item }}
            </Option>
          </Select>
        </div>
        <div class="search-input">
          <label for="">规格</label>
          <Select
            v-model="params.formate"
            placeholder="请选择规格"
            clearable
            @on-clear="getDataList"
            @on-change="getDataList"
          >
            <Option v-for="item of formateOptions" :key="item" :value="item">
              {{ item }}
            </Option>
          </Select>
        </div>
        <div class="search-input">
          <label for="">材质</label>
          <Select
            v-model="params.material"
            placeholder="请选择材质"
            clearable
            @on-clear="getDataList"
            @on-change="getDataList"
          >
            <Option v-for="item of materialOptions" :key="item" :value="item">
              {{ item }}
            </Option>
          </Select>
        </div>
        <Button type="error" @click="getDataList">查询</Button>
      </div>

      <PageSection>
        <template v-slot:title>供应</template>
        <div class="table">
          <Table :columns="columns" :data="dataList2">
            <template #avgPrice="{ row }">
              <!-- <span style="color: #de6a64">{{ row.avgPrice }}</span> -->
              <Numeral
                :value="row.avgPrice"
                format="0,0.00"
                class="table-text"
                style="color: #de6a64"
              >
                <template #prefix>
                  <strong>¥</strong>
                </template>
              </Numeral>
            </template>
          </Table>
        </div>
      </PageSection>
      <PageSection>
        <template v-slot:title>采购</template>
        <div class="table">
          <Table :columns="columns" :data="dataList1">
            <template #avgPrice="{ row }">
              <!-- <span style="color: #de6a64">{{ row.avgPrice }}</span> -->
              <Numeral
                :value="row.avgPrice"
                format="0,0.00"
                class="table-text"
                style="color: #de6a64"
              >
                <template #prefix>
                  <strong>¥</strong>
                </template>
              </Numeral>
            </template>
          </Table>
        </div>
      </PageSection>
    </div>
  </AppLayoutCommon>
  <AppFooter />
</template>

<script>
import AppLayoutCommon from '@/components/AppLayoutCommon'
import AppFooter from '@/components/AppFooter'
import AppTopNav from '@/components/AppTopNav'
export default {
  name: 'NeedsList',
  data () {
    return {
      productNameOptions: [],
      formateOptions: [],
      materialOptions: [],
      params: {},
      columns: [
        {
          title: '品名',
          key: 'productName',
        },
        {
          title: '规格/型号',
          key: 'formate',
        },
        {
          title: '材质',
          key: 'material',
        },
        {
          title: '价格',
          key: 'avgPrice',
          slot: 'avgPrice',
        },
        {
          title: '数量/重量',
          key: 'sumInventory',
        },
        {
          title: '单位',
          key: 'unitName',
        },
      ],
      dataList1: [],
      dataList2: [],
    }
  },
  setup () {},
  created () {
    this.getProductName()
    this.getFormateOptions()
    this.getMaterialOptions()
    this.getDataList()
  },
  methods: {
    getMaterialOptions () {
      this.$store
        .dispatch('Materials')
        .then((res) => {
          if (res.returnCode === '1') {
            this.materialOptions = res.result
          }
        })
        .catch(() => {})
    },
    getFormateOptions () {
      this.$store
        .dispatch('Formates')
        .then((res) => {
          if (res.returnCode === '1') {
            this.formateOptions = res.result
          }
        })
        .catch(() => {})
    },
    getProductName () {
      this.$store
        .dispatch('ProductNames')
        .then((res) => {
          if (res.returnCode === '1') {
            this.productNameOptions = res.result
          }
        })
        .catch(() => {})
    },
    getDataList () {
      this.getPurchaseStatistics()
      this.getSupplyStatistics()
    },
    /* 获取采购 */
    getPurchaseStatistics () {
      this.$store
        .dispatch('PurchaseStatistics', this.params)
        .then((res) => {
          if (res.returnCode === '1') {
            this.dataList1 = res.result
          }
        })
        .catch((e) => {
        })
    },

    /* 获取供应 */
    getSupplyStatistics () {
      this.$store
        .dispatch('SupplyStatistics', this.params)
        .then((res) => {
          if (res.returnCode === '1') {
            this.dataList2 = res.result
          }
        })
        .catch((e) => {
        })
    },

    tableSelectChange (val) {
      this.params.categoryId = val[1]
      this.getPurchaseStatistics()
    },
    pageChange (page) {
      this.pageInfo.pageIndex = page
      this.params.page = page
      this.getPurchaseStatistics()
    },
  },
  components: {
    AppLayoutCommon,
    AppFooter,
    AppTopNav,
  },
}
</script>
<style lang="less" scoped>
.main-content {
  padding-bottom: 30px;

  .search {
    display: flex;
    margin: 30px 0;

    .search-input {
      margin-right: 20px;
      display: flex;
      align-items: center;

      label {
        width: 100px;
        text-align: right;
        padding-right: 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
